import _upperFirst from 'lodash/upperFirst.js';

interface PydanticError {
  loc: string[];
  msg: string;
  type: string;
}

export const useFormHandling = () => {
  const getFieldErrorMessages = (errorDetail: PydanticError[]) => {
    return errorDetail.reduce((errors: Record<string, string>, detail: PydanticError) => {
      // in Pydantic v2, custom value errors are returned as "Value error, <message>" - we want to stripe the prefix
      const cleanMessage = _upperFirst(String(detail.msg).replace('Value error, ', ''));

      const fieldName = detail.loc[detail.loc.length - 1];

      if (!fieldName) {
        return errors;
      }

      errors[fieldName] = cleanMessage;
      return errors;
    }, {});
  };

  return {
    getFieldErrorMessages,
  };
};
